import { createAsync } from '@solidjs/router';
import { Button, Heading, HorizontalRule, Link, LinkButton, Tag } from '@troon/ui';
import { Suspense, createUniqueId, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { UserRewardsLevel, gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { useUser } from '../../providers/user';
import { dayTimeToDate } from '../../modules/date-formatting';
import { UsellAccessMinimal } from '../../components/upsells/access-minimal';
import type { CalendarDayTime } from '../../graphql';

export default function Settings() {
	const rewards = createAsync(() => getRewards({}), { deferStream: true });
	const progressId = createUniqueId();
	const user = useUser();

	return (
		<Suspense>
			<Show when={user()}>
				{(user) => (
					<>
						<h1 class="sr-only">My account</h1>
						<div class="flex flex-row items-center justify-between">
							<div class="flex flex-col gap-2">
								<Heading as="h2" size="h1">
									{user()?.me.firstName} {user()?.me.lastName}
								</Heading>
								<p class="order-first font-semibold uppercase text-brand-600">
									Troon Rewards #{user()?.me.troonRewardsId}
								</p>
								<p class="text-neutral-700">{user()?.me.email}</p>
							</div>
							<Button as={Link} href="/auth/logout" appearance="secondary" class="hidden shrink grow-0 lg:block">
								Log out
							</Button>
						</div>

						<Show when={user()?.me.card}>
							{(card) => (
								<p class="flex flex-row flex-wrap items-center justify-between gap-2 rounded bg-neutral-100 p-3">
									<span>
										Your <b>2024 {card().name}</b> expires on 12/31/2024.
									</span>
									<LinkButton href="/account/card">
										Learn more <Icon name="arrow-right-md" />
									</LinkButton>
								</p>
							)}
						</Show>

						<Show when={rewards()?.activeTroonCardSubscription} fallback={<UsellAccessMinimal location="account" />}>
							{(sub) => (
								<p class="flex flex-row flex-wrap items-center gap-2 rounded bg-neutral-100 p-3">
									<Tag appearance="access">{sub().name}</Tag>
									<span>
										You’re a <b>{sub().name}</b> member.
										<Show when={sub().nextInvoiceDate as CalendarDayTime}>
											{(date) => <> Renewing {dayjs(dayTimeToDate(date())).format('MM/DD/YYYY')}.</>}
										</Show>
									</span>
								</p>
							)}
						</Show>

						<Heading as="h3" size="h4">
							Troon Rewards
						</Heading>

						<div class="flex flex-col gap-6 rounded border border-neutral p-6">
							<div class="flex flex-row flex-wrap items-center justify-between gap-4">
								<div class="flex flex-col gap-4">
									<Heading as="h4" size="p" class="text-neutral-700">
										Total points available
									</Heading>
									<p class="text-5xl font-semibold">
										<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.availablePoints ?? 0)}</Suspense>
									</p>
								</div>
								<div class="flex grow flex-row flex-wrap items-center justify-end gap-4 md:flex-nowrap">
									<Button
										size="sm"
										as={Link}
										appearance="secondary"
										href="/rewards/redemption"
										class="size-fit shrink text-nowrap md:grow-0"
									>
										Redemption options
									</Button>
									<Button
										size="sm"
										as={Link}
										appearance="secondary"
										href="/account/activity"
										class="size-fit shrink text-nowrap md:grow-0"
									>
										View Activity
									</Button>
								</div>
							</div>

							<HorizontalRule />

							<div>
								<p>
									<span class="text-lg font-semibold">
										<Suspense fallback="…">{pointFormatter(rewards()?.userRewards.currentYearPoints ?? 0)}</Suspense>
									</span>{' '}
									<span class="text-neutral-700">
										points earned (<abbr title="Year to date">YTD</abbr>)
									</span>
								</p>

								<div
									role="progressbar"
									aria-valuenow={rewards()?.userRewards.availablePoints}
									aria-valuemin={0}
									aria-valuemax={
										(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0)
									}
									aria-valuetext={`${pointFormatter(rewards()?.userRewards.availablePoints ?? 0)} points`}
									aria-labelledby={progressId}
									class="h-6 rounded-full bg-brand-100"
								>
									<div
										class="h-6 min-w-6 rounded-full bg-brand"
										style={{
											width: `${((rewards()?.userRewards.availablePoints ?? 0) / ((rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0))) * 100}%`,
										}}
									/>
								</div>

								<Show when={rewards()?.userRewards.nextLevel}>
									<p class="text-right leading-none">
										<span class="block text-lg font-semibold">
											{pointFormatter(
												(rewards()?.userRewards.availablePoints ?? 0) + (rewards()?.userRewards.pointsToNextLevel ?? 0),
											)}
										</span>
										<Suspense>
											<span class="text-neutral-700">{levelToTitle[rewards()!.userRewards.nextLevel!]}</span>
										</Suspense>
									</p>
								</Show>
							</div>

							<p>
								<LinkButton href="/rewards/points-request" class="size-fit">
									Missing Points?
								</LinkButton>
							</p>
						</div>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const query = gql(`
query accountDetails {
  userRewards {
		availablePoints
		currentYearPoints
		previousYear1Points
		previousYear2Points
		previousYear3Points
		level
		pointsToNextLevel
		nextLevel
	}
	activeTroonCardSubscription {
		nextInvoiceDate { ...DayTime }
		name: subscriptionName
	}
}`);

const getRewards = cachedQuery(query);

const pointFormatter = new Intl.NumberFormat('en', {}).format;

const levelToTitle: Record<UserRewardsLevel, string> = {
	[UserRewardsLevel.Member]: 'Member',
	[UserRewardsLevel.Silver]: 'Silver',
	[UserRewardsLevel.Gold]: 'Gold',
	[UserRewardsLevel.Platinum]: 'Platinum',
};
